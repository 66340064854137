<template>
    <div class="orders-history">
         <div class="mb-10 order-history">
            <div class="mt-4 summary-wrapper">
                <div class="text-overline">Mes statistiques:</div>
                <v-carousel class="custom-carousel" :continuous="true" :show-arrows="true" height="100%" hide-delimiter-background delimiter-icon="mdi-minus">
                    <template v-slot:next="{on}">
                        <v-icon v-on="on">mdi-chevron-right</v-icon>
                    </template>
                    <template v-slot:prev="{on}">
                        <v-icon v-on="on">mdi-chevron-left</v-icon>
                    </template>
                    <v-carousel-item >
                        <v-card class="text-center" >
                            <v-card-text>
                                <v-simple-table>
                                    <template v-slot:default>
                                    <thead>
                                        <tr>
                                        <th class="text-left">Mois</th>
                                        <th class="text-left">Puissance</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(val, key) in value" :key="key">
                                        <td class="text-left">{{labels[key]}}</td>
                                        <td class="text-left">{{val}}kW</td>
                                        </tr>
                                    </tbody>
                                    </template>
                                </v-simple-table>
                            </v-card-text>
                        </v-card>
                    </v-carousel-item>
                    <v-carousel-item >
                        <v-card class="text-center" >
                            <v-card-text>
                                <v-simple-table>
                                    <template v-slot:default>
                                    <thead>
                                        <tr>
                                        <th class="text-left">Mois</th>
                                        <th class="text-left">Montant</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(val, key) in value" :key="key">
                                        <td class="text-left">{{labels[key]}}</td>
                                        <td class="text-left">{{formatPrice(val)}}€</td>
                                        </tr>
                                    </tbody>
                                    </template>
                                </v-simple-table>
                            </v-card-text>
                        </v-card>
                    </v-carousel-item>
                </v-carousel>
                <v-divider class="mt-3"></v-divider>
            </div>
            <template v-if="filteredOrders.length > 0">
                <template v-for="(order, index) in filteredOrders">
                    <div v-if="(showOnlyLastOrder && index == 0) || !showOnlyLastOrder"  :key="index" class="order">
                        <div class="coupon-text">
                            <div class="order-content order-type-fuel">
                                <div class="icon">
                                    <img style="width: 32px;" :src="getPicto(order.orderOrigin)" alt="">
                                </div>
                                <div class="order-product">
                                    <span class="order-type">{{order.creationDate}}</span>
                                    <span v-if="order.orderOrigin != 'IEC_PAY_AND_DRIVE'" class="order-nb-articles"> {{$t("ordersSummarryPaid", {nbrProducts: getNbrProducts(order)})}}</span>
                                    <span v-if="order.orderOrigin != 'IEC_PAY_AND_DRIVE'" @click="handleMoreDetails(order)" class="show-more" ><u class="mr-1">{{$t("ordersSeeMore")}}</u></span>
                                </div>
                                <span class="order-amount"><span>{{formatNumber(order.totalAmount + 1.51)}} €</span></span>
                            </div>
                            <div v-if="order.orderOrigin == 'IEC_PAY_AND_DRIVE'" class="pa-3">
                                <div class="pa-2 ticket-elmt ticket-body">
                                    <span class="pa-1 pb-0 text-caption">Elec - Plugsurfing</span>
                                    <span class="pa-1 pb-0 text-caption values">{{$t("ordersTotal")}}</span>

                                    <span class="pa-1 pt-0 font-weight-bold text-caption">{{order.basketProducts[0].qty}}kW <span class="text-caption">({{formatNumber(order.basketProducts[0].product.price, 3)}}€/min)</span></span>
                                    <span class="pa-1 pt-0 font-weight-bold text-caption values">{{formatNumber(order.totalAmount)}}€</span>

                                    <span class="pa-1 pb-0 text-caption">{{$t("ordersPenalties")}}</span>
                                    <span class="pa-1 pb-0 text-caption"></span>

                                    <span class="pa-1 pt-0 font-weight-bold text-caption">10 mins</span>
                                    <span class="pa-1 pt-0 font-weight-bold text-caption values">1.51€</span>
                                </div>
                                <v-divider></v-divider>
                                <PaymentCard />
                            </div>
                        </div>
                    </div>
                </template>
            </template>
            <template v-else>
                <span class="no-order-placeholder">{{$t("noOrderHistory")}}</span>
            </template>
            <div class="filter-wrapper">
                <div class="filter-bg"></div>
                <v-btn class="filter-btn" color primary @click="showFilters()">{{$t("orderFiltersBtnLabel")}}</v-btn>
                <div v-if="showFilterSection" :class="{'filter-section': true, 'show': startFilterTransition}">
                    <div class="d-flex align-center">
                        <span class="filter-section-title">{{$t("orderFiltersTitle")}}</span>
                        <v-spacer></v-spacer>
                        <v-btn @click="closeFilters()" icon><v-icon> mdi-close</v-icon></v-btn>
                    </div>
                    <div class="filter-options-wrapper">
                        <span class="option-title">{{$t("orderFiltersOrigin")}}</span>
                        <div class="filter-options">
                            <div class="filter-option" v-for="(origin, i) in filters.orderOrigin" :key="'label-' + i">
                                <div class="custom-radio-wrapper" @click="updateFilters(origin.value)">
                                    <span :class="{'custom-radio': true, 'checked': selectedFilters.indexOf(origin.value) != -1}"></span>
                                    <input type="checkbox" v-model="selectedFilters" :id="`filter-${i}`" :value="origin.value" required>
                                </div>
                                <label :for="`filter-${i}`">{{origin.label}}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import PaymentCard from "@/components/PaymentCard.vue"
import ProductBloc from "@/components/ProductBloc";
import { mapGetters, mapActions } from "vuex"
export default {
    components: { ProductBloc, PaymentCard }, 
    data(){
        return {
            orders: [],
            page: {
                title: "ordersPageTitle",
                description: null,
            },
            value: [ 510, 590, 610 ],
            labels: [ 'Septembre', 'Octobre', 'Novembre' ],
            showFilterSection: false,
            startFilterTransition: false,
            selectedFilters:[],
            showOrderpanel: [],
            showOnlyLastOrder: false,
            filters: {
                orderOrigin:[
                    {
                        label: "Click&Collect",
                        value: "IEC_CLICK_AND_COLLECT",
                    },
                    {
                        label: "Scan&Pay",
                        value: "IEC_SCAN_AND_PAY",
                    },
                    {
                        label: "Pay@Table",
                        value: "IEC_PAY_AT_TABLE",
                    },
                    {
                        label: "Pay&Drive",
                        value: "IEC_PAY_AND_DRIVE",
                    }
                ]
            }
        }
    },
    async created(){
        try {
            this.orders = await this.getAllFinishedOrders();
        } catch (e) {
            console.error(e)
        }

        if(this.$route.query.padOnly){
            this.selectedFilters = ["IEC_PAY_AND_DRIVE"]
            this.showOnlyLastOrder = true
        }else{
            this.selectedFilters = this.filtersValues
        }
    },
    methods: {
        ...mapActions({
            getAllFinishedOrders: "basket/getAllFinishedOrders"
        }),
        getNbrProducts(order){
            return order.basketProducts.reduce((acc, basketProduct) => {return acc + basketProduct.qty}, 0)
        },
        getPicto(origin){
            let picto = null
            switch(origin){
                case 'IEC_SCAN_AND_PAY':
                    picto = require("@/assets/img/picto_scan_pay.png")
                    break;
                case 'IEC_CLICK_AND_COLLECT':
                    picto = require("@/assets/img/picto_click_collect.png")
                    break;
                case 'IEC_PAY_AT_TABLE':
                    picto = require("@/assets/img/picto_pay_at_table.png")
                    break;
                case 'IEC_PAY_AND_DRIVE':
                    picto = require("@/assets/img/picto_pay_and_drive.png")
                    break;
            }
            return picto
        },
        showFilters(){
            this.showFilterSection = true
            setTimeout(() => {
                this.startFilterTransition = true
            }, 100);
        },
        closeFilters(){
            this.showFilterSection = false
            setTimeout(() => {
                this.startFilterTransition = false
            }, 100);
        },
        updateFilters(value){
            if(this.selectedFilters.indexOf(value) !== -1 ){
                this.selectedFilters = this.selectedFilters.filter(filter => filter != value)
            }else{
                this.selectedFilters.push(value)
            }
        },
        handleMoreDetails(order){
            if(order.show && order.show.length > 0){
                order.show.shift()
            }
            else{
                order.show = []
            }
        }
    },
    computed:{
        filteredOrders(){
            if(this.selectedFilters.length > 0){
                return this.orders.filter(order => this.selectedFilters.includes(order.orderOrigin))
            }
            return this.orders
        },
        filtersValues(){
            let values = []
            for(let filter in this.filters){
                values = [...values, ...this.filters[filter].map(f => f.value)]
            }
            return values
        }
    }
}
</script>
<style scoped>
    
    .ticket-elmt {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        align-items: center;
        justify-items: start;
        column-gap: 16px;
    }
    .ticket-elmt .values{
        justify-self: end;
    }
    .order-history{
        margin-top: 70px;
        margin-bottom: 16px;
    }
    .order-content{
        display: flex;
        flex-direction: column;
        height: fit-content;
        margin: 16px 0px;
    }
    .order-amount{
        align-self: flex-end;
        border-top: solid 1px;
        margin-top: 16px;
    }
    .order-amount span{
        font-weight: bold;
    }
    .order-header, .order-product{
        display: flex;
        justify-content: space-between;
    }

    .order {
    position: relative;
    box-sizing: border-box;
    width: 90%;
    margin: 16px auto 0;
    padding: 20px;
    border-radius: 3px;
    background: #FBFBFB;
    box-shadow: 3px 4px 5px #8080802e;
    }

    .order:before,
    .order:after {
        content: '';
        position: absolute;
        left: 0px;
        height: 6px;
        width: 100%;
        background: radial-gradient(circle, transparent, transparent 50%, #FBFBFB 50%, #FBFBFB 100% ) -7px -2px / 16px 16px repeat-x;
    }

    .order:before {
        top: -5px;
        transform: rotate(180deg);
    }

    .order:after {
        bottom: -5px;
    }
    .order-loyalty-points{
        font-size: 14px;
        font-style: italic;
        color: grey;
    }

    .no-order{
        text-align: center;
        font-style: italic;
    }
    .order-history{
        margin-top: 0;
        margin-bottom: 16px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 60px;
    }

    .order-product{
        font-style: italic !important;
        color: #46b8a6 !important;
        font-weight: bold !important;
        flex-direction: column !important;
    }
    .order-content{
        flex-direction: row !important;
        justify-content: space-between !important;
        align-items: center !important;
    }
    .order-nb-articles{
        font-size: .85rem !important;
        font-weight: normal !important;
        color: #444444 !important;
    }
    .order-amount{
        align-self: center !important; 
        border-top: none !important; 
        margin-top: 0 !important;
    }
    .order{
        padding: 8px;
    }
    .show-more{
        display: flex;
        align-items: center;
        font-weight: normal;
        font-style: italic;
        font-size: .85rem;
        color: grey;
    }
    .show-more span{
        font-size: .75rem;
    }
    .filter-wrapper{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: fixed;
        bottom: 0;
        left: auto;
        right: auto;
        margin-left: auto;
        width: 100%;
        z-index: 10;
    }
    .filter-wrapper .filter-btn{
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 8px auto;
        padding: 8px 24px;
        box-shadow: 0px 1px 8px #8080804a;
        /* border-radius: 50px; */
        color: white;
        background: #46b8a6;
    }
    .filter-bg{
        width: 100%;
        height: 100%;
        position: absolute;
        background: #ffffffb8;
        filter: blur(8px);
    }
    .filter-section{
        transition: all .5s ease-out;
        width: 90%;
        box-shadow: 0px 1px 8px #8080804a;
        border-top-right-radius: 12px;
        border-top-left-radius: 12px;
        padding: 8px 16px;
        position: absolute;
        z-index: 100;
        height: 0;
        opacity: 0;
        bottom: 0;
        background: white;
    }
    .filter-section.show{
        height: 350px;
        opacity: 1;
    }
    .filter-section-header{
        display: flex;
        justify-content: flex-end;
        padding: 8px 0;
        border-bottom: solid 1px #46b8a6;
        margin-bottom: 8px;
        font-size: 1.2rem;
    }
    .filter-options{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
    .filter-option{
        display: flex;
        align-items: baseline;
        justify-content: flex-start;
        grid-gap: 16px;
        padding: 8px;
    }
    .filter-option label{
        margin: 0;
    }
    .option-title{
        color: #46b8a6;
        font-weight: bold;
        font-size: 1.1rem;
    }
    .no-order-placeholder{
        font-style: italic;
        font-size: 1.2rem;
        color: grey;
        font-weight: bold;
    }

    .summary-wrapper{
        width: 90%;
        margin: 16px auto 0;
    }
    .custom-carousel .v-window__prev{
        margin: 0;
    }
</style>